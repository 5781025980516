import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"500","scrollable":""},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c(VCard,[_c('div',{staticClass:"d-flex justify-space-between pa-4"},[_c('span',{staticClass:"text-h6 font-medium primary--text"},[_vm._v("Konfirmasi Hapus "+_vm._s(_vm.formName))]),_c(VIcon,{on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1),_c(VCardText,{staticClass:"pt-2 job-form-content"},[_c('div',{staticClass:"mb-5 pb-5"},[_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_vm._v(" Nama "+_vm._s(_vm.formName)+" ")]),_c(VCol,{attrs:{"cols":"6"}},[_vm._v(" Jumlah "+_vm._s(_vm.formName)+" Masih Digunakan ")])],1),_c(VDivider,{staticClass:"mt-2 mb-5"}),_vm._l((_vm.deleteStatusList),function(status){return _c(VRow,{key:status.id,staticClass:"mt-3"},[_c(VCol,{staticClass:"my-auto",attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex"},[_c('span',[_vm._v(" "+_vm._s(status.name)+" ")]),_c('span',{staticClass:"ms-auto"},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiArrowRight)+" ")])],1)])]),_c(VCol,{attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(status.count)+" "+_vm._s(_vm.objName)+" ")])],1)})],2),_c('div',{staticClass:"d-flex flex-column flex-md-row mt-4"},[_c(VBtn,{staticClass:"mb-2 mb-md-0 mr-md-2 ml-md-auto",attrs:{"outlined":"","color":"error"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Batalkan ")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v(" Konfirmasi ")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }