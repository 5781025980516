<template>
  <div>
    <v-dialog
      v-model="isOpen"
      max-width="500"
      scrollable
    >
      <v-card>
        <div class="d-flex justify-space-between pa-4">
          <span class="text-h6 font-medium primary--text">Konfirmasi Hapus {{ formName }}</span>
          <v-icon
            @click="close()"
          >
            {{ icons.mdiClose }}
          </v-icon>
        </div>
        <v-card-text class="pt-2 job-form-content">
          <div class="mb-5 pb-5">
            <v-row>
              <v-col
                cols="6"
              >
                Nama {{ formName }}
              </v-col>
              <v-col
                cols="6"
              >
                Jumlah {{ formName }} Masih Digunakan
              </v-col>
            </v-row>
            <v-divider class="mt-2 mb-5" />
            <v-row
              v-for="status in deleteStatusList"
              :key="status.id"
              class="mt-3"
            >
              <v-col
                cols="6"
                class="my-auto"
              >
                <div class="d-flex">
                  <span>
                    {{ status.name }}
                  </span>
                  <span class="ms-auto">
                    <v-icon>
                      {{ icons.mdiArrowRight }}
                    </v-icon>
                  </span>
                </div>
              </v-col>
              <v-col
                cols="6"
              >
                {{ status.count }} {{ objName }}
              </v-col>
            </v-row>
          </div>
          <div class="d-flex flex-column flex-md-row mt-4">
            <v-btn
              class="mb-2 mb-md-0 mr-md-2 ml-md-auto"
              outlined
              color="error"
              @click="close()"
            >
              Batalkan
            </v-btn>
            <v-btn
              color="primary"
              @click="submitForm()"
            >
              Konfirmasi
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref } from '@vue/composition-api'
import { mdiClose, mdiArrowRight } from '@mdi/js'
import { apolloClient } from '@/vue-apollo'
import { confirmDeleteCustomAttributeOption } from '@/graphql/mutations'
import errorHandling from '@/utils/errorHandling'

export default {
  props: {
    objectTypeId: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isOpen = ref(false)
    const deleteStatusList = ref(null)
    const confirmationForm = ref(null)
    const formName = ref(null)
    const objName = ref(null)

    const show = (deleteList, name, name2) => {
      isOpen.value = true
      deleteStatusList.value = deleteList
      formName.value = name
      objName.value = name2
    }

    const close = () => {
      isOpen.value = false
    }

    const submitForm = async () => {
      if (formName.value === 'Opsi Atribut') {
        await apolloClient.mutate({
          mutation: confirmDeleteCustomAttributeOption,
          variables: {
            delete_id: deleteStatusList.value.map(status => status.id),
            object_type_id: props.objectTypeId,
          },
        }).catch(err => {
          errorHandling(err)
        })
      }

      emit('success')
      close()
    }

    return {
      isOpen,
      formName,
      objName,
      deleteStatusList,
      confirmationForm,

      show,
      close,
      submitForm,

      icons: {
        mdiClose,
        mdiArrowRight,
      },
    }
  },
}
</script>
<style>
</style>
