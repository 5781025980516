<template>
  <v-dialog
    v-model="isOpen"
    max-width="900"
  >
    <v-card>
      <div class="d-flex align-start pa-4">
        <span class="text-subtitle-1 text-center font-medium">
          {{ updateMode ? 'Edit atribut' : 'Tambah atribut baru' }}
        </span>
        <v-icon
          class="ml-auto mt-1"
          @click="close()"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <v-divider />
      <v-form
        ref="formCustomAttribute"
      >
        <v-row class="ma-3">
          <v-col
            cols="12"
            :md="updateMode ? 12 : 8"
          >
            <v-text-field
              v-model="customAttribute.name"
              label="Nama atribut"
              outlined
              dense
              hide-details="auto"
              :rules="[required]"
            />
          </v-col>
          <v-col
            v-if="!updateMode"
            :key="1"
            cols="12"
            md="4"
          >
            <v-select
              v-model="customAttribute.type"
              :items="attributeTypeList"
              :loading="loadingTypes"
              item-value="id"
              item-text="name"
              label="Tipe atribut"
              outlined
              dense
              hide-details="auto"
              :rules="[required]"
            />
          </v-col>
          <v-col
            v-if="customAttribute.type === 4 || customAttribute.type === 5"
          >
            <XyzTransitionGroup>
              <div
                v-for="(option, index) in customAttribute.options"
                :key="index"
                class="d-flex mt-2 align-items-start"
              >
                <v-text-field
                  v-model="option.name"
                  outlined
                  placeholder="Masukkan Nama Opsi"
                  dense
                  hide-details="auto"
                  :rules="[required]"
                />
                <v-btn
                  elevation="0"
                  icon
                  class="d-flex ml-2 rounded-lg align-self-center"
                  outlined
                  color="error"
                  @click="removeOption(index, option.id)"
                >
                  <v-icon
                    size="24"
                    color="error"
                  >
                    {{ icons.mdiTrashCan }}
                  </v-icon>
                </v-btn>
              </div>
            </XyzTransitionGroup>
            <v-btn
              outlined
              color="success"
              class="mt-4"
              @click="addOption"
            >
              Tambahkan Opsi
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-divider />
      <div class="d-flex align-items-start">
        <v-btn
          class="ma-4"
          color="primary"
          :loading="loadingAddTypes"
          :disabled="loadingAddTypes"
          outlined
          @click="validateForm()"
        >
          Simpan
        </v-btn>
        <v-btn
          class="ma-4"
          color="error"
          outlined
          :loading="loadingAddTypes"
          :disabled="loadingAddTypes"
          @click="close()"
        >
          Batalkan
        </v-btn>
      </div>
    </v-card>
    <custom-attribute-confirmation
      ref="customAttributeConfirmation"
      :object-type-id="objectTypeId"
      @success="submitUpdate()"
    />
  </v-dialog>
</template>
<script>
import Vue from 'vue'
import { ref, computed } from '@vue/composition-api'
import useCustomAttributes from '@/composables/useCustomAttributes'
import { mdiClose, mdiTrashCan } from '@mdi/js'
import { required } from '@core/utils/validation'
import CustomAttributeConfirmation from './CustomAttributeConfirmation.vue'
import { apolloClient } from '@/vue-apollo'
import { queryDeleteCustomAttributeOption } from '@/graphql/queries'
import errorHandling from '@/utils/errorHandling'
import { deleteCustomAttributeOption } from '@/graphql/mutations'

export default {
  components: {
    CustomAttributeConfirmation,
  },
  props: {
    objectTypeId: {
      type: Number,
      required: true,
    },
    customAttributes: {
      type: Array,
      default: () => [],
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isOpen = ref(false)
    const updateMode = ref(false)
    const formCustomAttribute = ref(null)
    const computedCustomAttributes = computed(() => props.customAttributes)
    const customAttributeConfirmation = ref(null)
    const customAttribute = ref({
      name: null,
      type: null,
      options: [],
      delete_options: [],
    })
    const {
      fetchAttributeTypes, loadingTypes, attributeTypeList,
      createCustomAttribute, loadingAddTypes, editCustomAttribute,
    } = useCustomAttributes({ objectTypeId: props.objectTypeId }) // 1: task; 2: job; 4: customer

    const open = () => {
      updateMode.value = false
      isOpen.value = true
      customAttribute.value = {
        name: null,
        type: null,
        options: [],
        delete_options: [],
      }
      fetchAttributeTypes()
    }

    const edit = (data, index) => {
      if (index !== undefined) {
        customAttribute.value.index = index
      }
      customAttribute.value.name = data.name
      customAttribute.value.id = data.id
      customAttribute.value.type = data.data_type.id
      customAttribute.value.options = data.options.map(option => ({ id: option.id, name: option.name }))
      updateMode.value = true
      isOpen.value = true
    }

    const close = () => {
      emit('update')
      customAttribute.value = {
        name: null,
        type: null,
        options: [],
        delete_options: [],
      }
      formCustomAttribute.value.reset()
      isOpen.value = false
    }

    const checkOptions = async () => {
      const objectType = {
        1: 'task',
        2: 'job',
        4: 'customer',
      }
      if (customAttribute.value.delete_options.length) {
        return apolloClient.query({
          query: queryDeleteCustomAttributeOption,
          fetchPolicy: 'no-cache',
          variables: {
            delete_id: customAttribute.value.delete_options,
            object_type_id: props.objectTypeId,
          },
        }).then(async result => {
          if (result.data.queryDeleteCustomAttributeOption.length > 0) {
            customAttributeConfirmation.value.show(result.data.queryDeleteCustomAttributeOption, 'Opsi Atribut', objectType[props.objectTypeId])

            return Promise.resolve(false)
          }

          return Promise.resolve(true)
        }).catch(err => {
          errorHandling(err)
        })
      }

      return Promise.resolve(true)
    }

    const submitUpdate = () => {
      if (props.returnObject) {
        if (updateMode.value) {
          const idx = customAttribute.value.index
          if (customAttribute.value.delete_options.length) {
            apolloClient.mutate({
              mutation: deleteCustomAttributeOption,
              fetchPolicy: 'no-cache',
              variables: {
                id: customAttribute.value.delete_options,
              },
            }).then(() => {
              Vue.notify({
                title: 'Sukses!',
                text: 'Berhasil menghapus opsi atribut!',
              })
            }).catch(error => {
              errorHandling(error)
            })
          }
          computedCustomAttributes.value[idx].name = customAttribute.value.name
          computedCustomAttributes.value[idx].options = customAttribute.value.options.map(option => ({ id: option.id, name: option.name }))
        } else {
          computedCustomAttributes.value.push({
            name: customAttribute.value.name,
            data_type: {
              id: customAttribute.value.type,
              name: attributeTypeList.value.find(el => el.id === customAttribute.value.type).name,
            },
            options: customAttribute.value.options.map(option => ({ id: option.id, name: option.name })),
          })
        }
        formCustomAttribute.value.reset()
        isOpen.value = false

        return
      }

      if (updateMode.value) {
        editCustomAttribute(customAttribute.value).then(() => close())
      } else {
        customAttribute.value.options = customAttribute.value.options.map(el => el.name)
        createCustomAttribute(customAttribute.value).then(() => close())
      }
    }

    const submitForm = async () => {
      if (await checkOptions()) {
        submitUpdate()
      }
    }

    const validateForm = () => {
      if (formCustomAttribute.value.validate()) {
        submitForm()
      }
    }

    const addOption = () => {
      customAttribute.value.options.push({ id: null, name: null })
    }

    const removeOption = (index, id) => {
      customAttribute.value.options.splice(index, 1)
      if (id) {
        customAttribute.value.delete_options.push(id)
      }
    }

    return {
      isOpen,
      open,
      edit,
      close,
      customAttribute,
      customAttributeConfirmation,
      loadingTypes,
      attributeTypeList,
      icons: {
        mdiClose,
        mdiTrashCan,
      },
      removeOption,
      submitForm,
      submitUpdate,
      required,
      formCustomAttribute,
      loadingAddTypes,
      validateForm,
      updateMode,
      addOption,
    }
  },
}
</script>
<style></style>
