<template>
  <v-dialog
    v-model="isOpen"
    scrollable
    max-width="1200px"
  >
    <v-card>
      <div class="d-flex align-start pa-4">
        <span class="text-subtitle-1 text-center font-medium">
          Atribut kustom
        </span>
        <v-icon
          class="ml-auto mt-1"
          @click="close()"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <v-divider />
      <PerfectScrollbar
        :options="{
          useBothWheelAxes:true
        }"
        class="pa-4"
      >
        <draggable
          :list="customAttributesArray"
          :animation="200"
          ghost-class="ghost-card"
          group="value"
          :delay="500"
          :delay-on-touch-only="true"
        >
          <div
            v-for="(column, index) in customAttributesArray"
            :id="column.custom_attribute.id"
            :key="column.custom_attribute.id"
            class="d-flex mt-1 py-2 px-2 align-items-start"
          >
            <v-icon
              size="22"
              class="mr-2 ms-1 text--disabled mt-2"
            >
              {{ icons.mdiDrag }}
            </v-icon>

            <div class="custom-attribute-name">
              <v-text-field
                type="text"
                outlined
                :value="column.custom_attribute.name"
                disabled
                hide-details="auto"
                dense
                wid
              />
            </div>
            <div class="custom-attribute-value ml-3">
              <v-text-field
                v-if="column.custom_attribute.data_type.id === 1 || column.custom_attribute.data_type.id === 2"
                v-model="customAttributesArray[index].value"
                :type="column.custom_attribute.data_type.id === 1 ? 'text' : 'number'"
                outlined
                :placeholder="column.custom_attribute.name"
                hide-details="auto"
                dense
                class="flex-wrap"
              />
              <date-time-picker
                v-else-if="column.custom_attribute.data_type.id === 3"
                v-model="customAttributesArray[index].value"
                :placeholder="column.custom_attribute.name"
              />
              <v-select
                v-else-if="column.custom_attribute.data_type.id === 4 || column.custom_attribute.data_type.id === 5"
                v-model="customAttributesArray[index].value"
                dense
                outlined
                hide-details="auto"
                :items="column.custom_attribute.options"
                :multiple="column.custom_attribute.data_type.id === 5"
                :small-chips="column.custom_attribute.data_type.id === 5"
                item-text="name"
                item-value="id"
                clearable
                :placeholder="column.custom_attribute.name"
              />
            </div>

            <v-btn
              elevation="0"
              icon
              class="d-flex ml-2 rounded-lg align-self-center"
              outlined
              color="warning"
              @click="$refs.customAttributeForm.edit(column.custom_attribute)"
            >
              <v-icon
                size="24"
                color="warning"
              >
                {{ icons.mdiPencil }}
              </v-icon>
            </v-btn>

            <v-btn
              elevation="0"
              icon
              class="d-flex ml-2 rounded-lg align-self-center"
              outlined
              color="error"
              @click="confirmDelete(column.custom_attribute.id)"
            >
              <v-icon
                size="24"
                color="error"
              >
                {{ icons.mdiTrashCan }}
              </v-icon>
            </v-btn>
          </div>
        </draggable>
      </PerfectScrollbar>
      <div>
        <v-btn
          class="ma-4"
          outlined
          color="primary"
          @click="$refs.customAttributeForm.open()"
        >
          Tambah atribut baru
        </v-btn>
        <v-btn
          outlined
          color="warning"
          :disabled="!isChanged"
          @click="saveAttributesValue()"
        >
          Simpan Perubahan
        </v-btn>
      </div>
    </v-card>
    <custom-attribute-form
      ref="customAttributeForm"
      :object-type-id="objectTypeId"
      @update="fetchCustomAttributes(objectId, jobTypeId); $emit('update')"
    />
  </v-dialog>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import {
  mdiClose, mdiDrag, mdiPencil, mdiTrashCan,
} from '@mdi/js'
import draggable from 'vuedraggable'
import useCustomAttribute from '@/composables/useCustomAttributes'
import CustomAttributeForm from './CustomAttributeForm.vue'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import Vue from 'vue'
import DateTimePicker from '@/components/inputs/DateTimePicker.vue'

export default {
  components: {
    draggable,
    CustomAttributeForm,
    PerfectScrollbar,
    DateTimePicker,
  },
  props: {
    objectId: {
      type: Number,
      required: true,
    },
    objectTypeId: {
      type: Number,
      required: true,
    },
    jobTypeId: {
      type: Number,
      default: null,
      required: false,
    },
  },
  setup(props, { emit }) {
    const initialValue = ref(null)
    const isOpen = ref(false)
    const {
      loadingFetch, fetchCustomAttributes, customAttributesArray,
      removeCustomAttribute,
      editCustomAttributeValue,
      loadingEditTypes,
    } = useCustomAttribute({ objectTypeId: props.objectTypeId }) // 1: task; 2: job; 4: customer

    const isChanged = ref(false)

    watch(customAttributesArray, () => {
      isChanged.value = initialValue.value && initialValue.value?.toString() !== customAttributesArray.value.map(el => el.value).toString()
    }, { deep: true })

    const confirmDelete = id => {
      Vue.$dialog({
        title: 'Hapus atribut?',
        body: 'Konfirmasi jika anda ingin menghapus atribut ini.',
        // eslint-disable-next-line consistent-return
      }).then(confirm => {
        if (confirm) {
          removeCustomAttribute(id).then(() => {
            fetchCustomAttributes(props.objectId).then(() => {
              emit('update')
            })
          })
        }
      })
    }

    const open = () => {
      isOpen.value = true
      fetchCustomAttributes(props.objectId, props.jobTypeId).then(result => {
        initialValue.value = result.map(el => el.value)
      })
    }

    const close = () => {
      isOpen.value = false
      customAttributesArray.value = []
    }

    const saveAttributesValue = () => {
      const payload = customAttributesArray.value.map(item => {
        let val
        if (item.custom_attribute.data_type.id === 3) val = item.value ? new Date(item.value) : null
        else if (item.custom_attribute.data_type.id === 4) val = item.value?.toString() || null
        else if (item.custom_attribute.data_type.id === 5) val = JSON.stringify(item.value.sort((a, b) => a - b) || [])
        else val = item.value

        return {
          id: item.custom_attribute.id,
          value: val,
        }
      })
      console.log(payload)
      editCustomAttributeValue({ objectId: props.objectId, typeId: props.objectTypeId, values: payload }).then(() => {
        emit('update')
      })
    }

    return {
      isOpen,
      open,
      close,
      icons: {
        mdiClose,
        mdiDrag,
        mdiPencil,
        mdiTrashCan,
      },
      loadingFetch,
      loadingEditTypes,
      fetchCustomAttributes,
      confirmDelete,
      removeCustomAttribute,
      customAttributesArray,
      saveAttributesValue,
      isChanged,
    }
  },

}
</script>
<style>
  .custom-attribute-name {
    width: 200px;
    min-width: 125px;
  }
  .custom-attribute-value {
    width: 500px;
    min-width: 200px;
  }
</style>
