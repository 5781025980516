<template>
  <v-dialog
    v-model="isOpen"
    max-width="800"
    scrollable
    @input="!$event ? reset() : null"
  >
    <v-card>
      <div class="d-flex justify-space-between pa-4">
        <span class="text-h6 font-medium primary--text">{{ updateMode ? 'Ubah Pelanggan' : 'Tambah Pelanggan' }}</span>
        <v-icon
          @click="close()"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <v-card-text class="pt-2 customer-form-content">
        <v-form
          ref="customerForm"
          lazy-validation
          @submit.prevent="submitForm"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="formData.name"
                :rules="[required]"
                label="Nama (wajib diisi)"
                placeholder="Input nama"
                outlined
                dense
                hide-details="auto"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="formData.gender"
                :items="[
                  {
                    value: 'Laki - Laki',
                    label: 'Laki - Laki',
                  },
                  {
                    value: 'Perempuan',
                    label: 'Perempuan',
                  },
                ]"
                label="Gender (wajib diisi)"
                outlined
                item-text="label"
                item-value="value"
                dense
                :rules="[required]"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="formData.phone_number"
                :rules="[required, phoneValidator]"
                label="Nomor HP (wajib diisi)"
                placeholder="Input nomor HP pelanggan"
                outlined
                dense
                hint="Contoh: 08951234567"
                persistent-hint
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <city-picker
                v-model="formData.city"
                :rules="[ required ]"
                label="Kota Asal"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="formData.job_title"
                label="Pekerjaan"
                placeholder="Input pekerjaan"
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-menu
                v-model="showDate"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="formData.birth_date"
                    label="Tanggal Lahir"
                    readonly
                    outlined
                    dense
                    :rules="[required]"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="formData.birth_date"
                  locale="id-ID"
                  :max="maxDate"
                  @change="showDate = false"
                />
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="formData.whatsapp_number"
                label="Nomor WhatsApp"
                placeholder="Input nomor WhatsApp pelanggan"
                outlined
                dense
                hint="Contoh: 08951234567"
                persistent-hint
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="formData.email"
                label="Email"
                placeholder="Input email pelanggan"
                outlined
                dense
                type="email"
                :rules="[emailValidator]"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="formData.facebook"
                label="Facebook"
                placeholder="Input Facebook pelanggan"
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="formData.instagram"
                label="Instagram"
                placeholder="Input Instagram pelanggan"
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-textarea
                v-model="formData.description"
                label="Deskripsi"
                placeholder="Isi deskripsi pelanggan"
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-textarea
                v-model="formData.address"
                label="Alamat"
                placeholder="Isi alamat pelanggan"
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-card
                outlined
                class="pa-3 mb-4 d-flex flex-column"
              >
                <span class="text-subtitle-2 font-medium mb-3">Atribut Lainnya</span>
                <div
                  v-for="(attribute, index) in customAttributesArray"
                  :key="attribute.custom_attribute.id"
                  class="mb-2"
                >
                  <v-text-field
                    v-if="attribute.custom_attribute.data_type.id === 1 || attribute.custom_attribute.data_type.id === 2"
                    v-model="customAttributesArray[index].value"
                    :type="attribute.custom_attribute.data_type.id === 1 ? 'text' : 'number'"
                    :label="attribute.custom_attribute.name"
                    :placeholder="'Input ' + attribute.custom_attribute.name + ' pelanggan'"
                    outlined
                    dense
                    hide-details="auto"
                    class="mb-2"
                  />
                  <div class="mb-2">
                    <DateTimePicker
                      v-if="attribute.custom_attribute.data_type.id === 3"
                      v-model="customAttributesArray[index].value"
                      :label="attribute.custom_attribute.name"
                      :placeholder="attribute.custom_attribute.name"
                    />
                  </div>
                  <v-autocomplete
                    v-if="attribute.custom_attribute.data_type.id === 4 || attribute.custom_attribute.data_type.id === 5"
                    v-model="customAttributesArray[index].value"
                    :items="attribute.custom_attribute.options"
                    :label="attribute.custom_attribute.name"
                    :placeholder="attribute.custom_attribute.name"
                    outlined
                    dense
                    hide-details="auto"
                    :multiple="attribute.custom_attribute.data_type.id === 5"
                    :small-chips="attribute.custom_attribute.data_type.id === 5"
                    item-text="name"
                    item-value="id"
                    class="mb-2"
                  />
                </div>
                <v-btn
                  text
                  elevation="0"
                  block
                  outlined
                  color="primary"
                  @click="updateMode ? $refs.customAttributesList.open() : $refs.customAttributeForm.open()"
                >
                  <v-icon>
                    {{ icons.mdiPlus }}
                  </v-icon>
                </v-btn>
              </v-card>
            </v-col>
            <v-col
              v-if="$can('update', 'CustomerTypeAssign')"
              cols="12"
              md="6"
            >
              <tag-customer
                v-model="formData.types"
                :read-only="!$can('update', 'CustomerType') && !$can('del', 'CustomerType')"
              />
            </v-col>
          </v-row>
          <div class="d-flex flex-column flex-md-row mt-4">
            <v-btn
              class="mb-2 mb-md-0 mr-md-2 ml-md-auto"
              outlined
              @click="reset()"
            >
              Reset Form
            </v-btn>
            <v-btn
              color="primary"
              :loading="loadingSubmit"
              @click="submitForm()"
            >
              Simpan Pelanggan
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
      <custom-attribute-form
        ref="customAttributeForm"
        :object-type-id="4"
        @update="$emit('success'); close()"
      />
      <custom-attributes-list
        v-if="formData.id"
        ref="customAttributesList"
        :object-id="formData.id"
        :object-type-id="4"
        @update="$emit('success'); close()"
      />
    </v-card>
    <v-overlay
      opacity="0.2"
      :value="loadingDetail"
    >
      <v-progress-circular
        indeterminate
        size="32"
      />
    </v-overlay>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import { ref } from '@vue/composition-api'
import { mdiClose, mdiPlus } from '@mdi/js'
import DateTimePicker from '@/components/inputs/DateTimePicker.vue'
import { required, emailValidator, phoneValidator } from '@core/utils/validation'
import { format, parseISO } from 'date-fns'
import TagCustomer from '@/components/inputs/TagCustomer.vue'
import { apolloClient } from '@/vue-apollo'
import { addCustomer, assignCustomerTypes, updateCustomer } from '@/graphql/mutations'
import { customerDetail } from '@/graphql/queries'
import router from '@/router'
import errorHandling from '@/utils/errorHandling'
import store from '@/store'
import CityPicker from '@/components/inputs/CityPicker.vue'
import useCustomAttributes from '@/composables/useCustomAttributes'
import CustomAttributesList from '@/views//custom-attributes/CustomAttributesList.vue'
import CustomAttributeForm from '@/views/custom-attributes/CustomAttributeForm.vue'

export default {
  components: {
    TagCustomer,
    CityPicker,
    CustomAttributesList,
    CustomAttributeForm,
    DateTimePicker,
  },
  setup(props, { emit }) {
    const customerForm = ref()
    const isOpen = ref(false)
    const formData = ref({
      id: null,
      name: '',
      gender: '0',
      description: '',
      address: '',
      job_title: '',
      birth_date: '',
      phone_number: '',
      whatsapp_number: '',
      email: '',
      instagram: '',
      facebook: '',
      types: [],
      city: null,
    })
    const showDate = ref(false)
    const maxDate = format(parseISO(new Date().toISOString()), 'yyyy-MM-dd')
    const loadingSubmit = ref(false)
    const loadingDetail = ref(false)
    const updateMode = ref(false)

    const { customAttributesArray, fetchCustomAttributes, getAttributeValue } = useCustomAttributes({ objectTypeId: 4 })

    const reset = () => {
      updateMode.value = false
      loadingSubmit.value = false
      loadingDetail.value = false
      customAttributesArray.value = customAttributesArray.value.map(el => ({ ...el, value: null }))

      // reset
      formData.value = {
        id: null,
        name: '',
        gender: '0',
        description: '',
        address: '',
        job_title: '',
        birth_date: '',
        phone_number: '',
        whatsapp_number: '',
        email: '',
        instagram: '',
        facebook: '',
        types: [],
        city: null,
      }

      customerForm.value.reset()
    }
    const show = name => {
      if (name) {
        formData.value.name = name
      }
      fetchCustomAttributes()
      isOpen.value = true
    }
    const close = () => {
      isOpen.value = false
      reset()
    }

    const update = id => {
      isOpen.value = true
      loadingDetail.value = true
      apolloClient.query({
        query: customerDetail,
        fetchPolicy: 'no-cache',
        variables: {
          customer_id: +id,
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      }).then(result => {
        const data = result.data.customerDetail
        formData.value = {
          id,
          name: data.name,
          gender: data.gender,
          description: data.description,
          address: data.address,
          job_title: data.job_title,
          birth_date: data.birth_date,
          phone_number: data.phone_number,
          whatsapp_number: data.whatsapp_number,
          email: data.email,
          instagram: data.instagram,
          facebook: data.facebook,
          city: data.city,
          types: data.type.map(el => el.id),
        }
        fetchCustomAttributes(formData.value.id)
        updateMode.value = true
        loadingDetail.value = false
      }).catch(err => {
        errorHandling(err)
        close()
      })
    }

    const submitForm = () => {
      if (customerForm.value.validate()) {
        loadingSubmit.value = true

        const payloadAttr = customAttributesArray.value.map(item => {
          let val
          if (item.custom_attribute.data_type.id === 3) val = item.value ? new Date(item.value) : null
          else if (item.custom_attribute.data_type.id === 4) val = item.value?.toString() || null
          else if (item.custom_attribute.data_type.id === 5) val = item.value && item.value.length ? JSON.stringify(item.value.sort((a, b) => a - b)) : null
          else val = item.value || null

          return {
            id: item.custom_attribute.id,
            value: val,
          }
        })
        console.log(payloadAttr)

        if (!updateMode.value) {
          apolloClient
            .mutate({
              mutation: addCustomer,
              variables: {
                ...formData.value,
                birth_date: new Date(Date.parse(formData.value.birth_date)),
                gender: typeof formData.value.gender === 'object' ? formData.value.gender.value : formData.value.gender,
                workspace_id: store.getters.getCurrentWorkspaceId,
                city_id: formData.value.city ? formData.value.city.id : null,
                custom_attribute: payloadAttr,
              },
            })
            .then(async result => {
              Vue.notify({
                title: 'Sukses!',
                text: 'Berhasil menambah pelanggan!',
              })
              const { id } = JSON.parse(result.data.addCustomer.data)
              emit('afterSubmit', id)
              loadingSubmit.value = false
              if (formData.value.types.length) {
                await apolloClient.mutate({
                  mutation: assignCustomerTypes,
                  variables: {
                    customer_id: id,
                    customerType: formData.value.types,
                    workspace_id: store.getters.getCurrentWorkspaceId,
                  },
                }).then(() => {
                  Vue.notify({
                    title: 'Sukses!',
                    text: 'Berhasil menambah tipe pelanggan!',
                  })
                }).catch(err => {
                  errorHandling(err, 'Assign Tipe Pelanggan')
                })
              }

              emit('success')
              router.push({ name: 'customer-detail', params: { id } })
            })
            .catch(err => {
              loadingSubmit.value = false
              errorHandling(err, 'Tambah Pelanggan')
            })
        } else {
          apolloClient
            .mutate({
              mutation: updateCustomer,
              variables: {
                ...formData.value,
                birth_date: new Date(Date.parse(formData.value.birth_date)),
                gender: typeof formData.value.gender === 'object' ? formData.value.gender.value : formData.value.gender,
                workspace_id: store.getters.getCurrentWorkspaceId,
                id: +formData.value.id,
                city_id: formData.value.city ? formData.value.city.id : null,
                custom_attribute: payloadAttr,
              },
            })
            .then(async result => {
              loadingSubmit.value = false
              await apolloClient.mutate({
                mutation: assignCustomerTypes,
                variables: {
                  customer_id: +formData.value.id,
                  customerType: formData.value.types,
                  workspace_id: store.getters.getCurrentWorkspaceId,
                },
              }).then(() => {
                Vue.notify({
                  title: 'Sukses!',
                  text: 'Berhasil mengubah tipe pelanggan!',
                })
              }).catch(err => {
                errorHandling(err, 'Assign Tipe Pelanggan')
              })

              emit('success', result)
              Vue.notify({
                title: 'Sukses!',
                text: 'Berhasil mengubah pelanggan!',
              })
              close()
            })
            .catch(err => {
              errorHandling(err, 'Ubah Pelanggan')
              loadingSubmit.value = false
            })
        }
      }
    }

    return {
      customerForm,
      isOpen,
      formData,
      loadingSubmit,
      loadingDetail,
      updateMode,
      showDate,
      maxDate,

      show,
      close,
      reset,
      update,
      submitForm,
      customAttributesArray,
      getAttributeValue,

      icons: {
        mdiClose,
        mdiPlus,
      },

      required,
      emailValidator,
      phoneValidator,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.customer-form-content {
  @include style-scroll-bar();
  padding-right: 12px !important;
  overflow-x: hidden;
  overflow-y: scroll !important;
}
</style>
